import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const LoaderWrapper = styled.div`
  .bounce1, .bounce2, .bounce3 {
    background-color: gray;
  }

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  
  ${({ isHorizontal }) => isHorizontal && css`
    float: left;
    height: 70px;
    justify-content: center;
    width: 100%;
  `}
`;

const Spinner = styled.div`
  height: 10px;
  div {
    width: 10px;
    height: 10px;
    background-color: rgba(0,0,0,0.35);

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    margin-right: $hmargin/2;
    &:last-child{
      margin-right: 0;
    }
  }
  &.white div{
    background-color: rgba(255,255,255,0.55);
  }
  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
`;

const Loading = ({ isHorizontal }) =>
  <LoaderWrapper isHorizontal={isHorizontal}>
    <Spinner>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </Spinner>
  </LoaderWrapper>;

Loading.propTypes = {
  isHorizontal: PropTypes.bool
};

export default Loading;
