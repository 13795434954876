import React,{ Component } from "react";
import {FormContainer, TitleBlock, FormSection} from "../RegistrationForm/styles";
import {Button, ButtonContainer} from "../RegistrationWellnessInterests/styles";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import FormWizardSteps from '../FormWizardSteps';
import {Layout} from './style';
import {CustomDropDown, CustomMenuItem} from "../RegistrationGeneralHealth/styles";
import { registrationAdditionalFields, registrationDepartmentFields } from "../../../mockData";
import {getUserInformation, saveUserInformation} from "../../redux/actions/registerActions";
import _ from "lodash";
import Waiting from '.././Waiting';

class LocationDepartment extends  Component {
  constructor(props) {
    super(props);
    this.state = {
      open: ''
    }
  }
  componentDidMount() {
    const{getUserInformation} = this.props;
    getUserInformation();
  }

  saveSpouseDependentInfo = () => {
    const {history} = this.props;
    history.push('/user/step6');
  };
  changeOpen = (index) => {
    if(index == this.state.open) {
      this.setState({ open: ''})
    }
    else {
      this.setState({ open: index });
    }
  }
  renderCompanyLocationDropDown = (item, dropDownValues, keyValue) => {
    const { saveUserInformation, userData } = this.props;
    const locationIndex = dropDownValues && dropDownValues.findIndex((location) => location.id === userData[keyValue]);
    let selectedValue = null;
    if(locationIndex > -1) {
      selectedValue = dropDownValues[locationIndex][keyValue];
    }
    const ShowTitle = 1;
    return(
      <CustomDropDown
        title={ selectedValue ? selectedValue : item.title}
        key={item.title}
        id={keyValue}
        onClick={() => this.changeOpen(keyValue)}
        opened={keyValue === this.state.open ? 1 : 0 }
        onBlur={() => this.changeOpen('')}
        company={1}
      >
        { ShowTitle && <CustomMenuItem key={item.title+'header'} disabled>{item.title}</CustomMenuItem> }
        {
          dropDownValues && dropDownValues.map((value, index) => (
            <CustomMenuItem
              eventKey={value.location + index} key={value[keyValue] + index}
              onSelect={() => saveUserInformation(keyValue, value.id)}
            >
              {value[keyValue]}
            </CustomMenuItem>
          ))
        }
      </CustomDropDown>
    )
  };
  render(){
    const {history, locationDetails, departmentDetails, userData} = this.props;
    if(_.isNull(locationDetails) || _.isUndefined(locationDetails)|| _.isNull(departmentDetails) || _.isUndefined(departmentDetails) || _.isNull(userData) || _.isUndefined(userData)) {
      return <Waiting />
    }
    return (
      <Layout>
        <FormContainer>
          <TitleBlock>
            Select {departmentDetails && departmentDetails.length > 0 && locationDetails && locationDetails.length > 0 ? 'Locations/Departments' : locationDetails && locationDetails.length > 0 ? 'Locations' : departmentDetails && departmentDetails.length > 0 ? 'Departments': null } <span> Step 5</span>
          </TitleBlock>
          <FormWizardSteps history={history} activeStep={5} />
          <div>
            { (departmentDetails && departmentDetails.length > 0 && locationDetails && locationDetails.length > 0 )
              ?
              <div>
                <FormSection setWidth={1} className="inside">
                  { locationDetails && locationDetails.length > 0 &&
          this.renderCompanyLocationDropDown(registrationAdditionalFields, locationDetails, 'location')
                  }
                </FormSection>
                <FormSection setWidth={1} className="inside">
                  { departmentDetails && departmentDetails.length > 0 &&
          this.renderCompanyLocationDropDown(registrationDepartmentFields, departmentDetails, 'department')
                  }
                </FormSection>
              </div>
              :
              <div className="register">
                { departmentDetails && departmentDetails.length > 0 &&
          this.renderCompanyLocationDropDown(registrationDepartmentFields, departmentDetails, 'department')
                }
                { locationDetails && locationDetails.length > 0 &&
          this.renderCompanyLocationDropDown(registrationAdditionalFields, locationDetails, 'location')
                }
              </div>
            }
          </div>
          <ButtonContainer>
            <Button color={'#bbbbbb'} onClick={() => history.push('/user/step4')}>
              <i className="fas fa-chevron-left" />
              previous
            </Button>
            <Button onClick={this.saveSpouseDependentInfo} disabled={(locationDetails && locationDetails.length > 0 && !userData['location'])
              ||(departmentDetails && departmentDetails.length > 0 && !userData['department'])
            }>
              next
              <i className="fas fa-chevron-right" />
            </Button>
          </ButtonContainer>
        </FormContainer>
      </Layout>
    )
  }
}

LocationDepartment.propTypes = {
  history: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  getUserInformation: PropTypes.func.isRequired,
  saveUserInformation: PropTypes.func.isRequired,
  // companyId: PropTypes.number.isRequired,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
};

const mapStateToProps = (state) => ({
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  userData: state.register.userData
});

const mapDispatchToProps = (dispatch) => ({
  getUserInformation: () => dispatch(getUserInformation()),
  saveUserInformation: (name, value) => dispatch(saveUserInformation(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationDepartment)
