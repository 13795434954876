/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from '../PeerRecognition/styles';
import { StyledModal, StyledHeader, StyledBody, Container, LeftSection, RightSection, Img, Content, Button, HeaderText } from './styles';
import { connect } from 'react-redux';
import { getPostLikeDetails, unFriendUser, sendFriendRequest } from '../../redux/actions';
import { withTranslation } from 'react-i18next';

class LikePopUp extends Component {

  componentDidMount(){
    const { getPostLikeDetails, feedNumber } = this.props;
    getPostLikeDetails(feedNumber);
  }

  handleRequest = ( status, invitee_id ) => {
    const { unFriendUser, sendFriendRequest, onClose } = this.props;
    if(status===1 || status===2){
      unFriendUser(invitee_id, false);
      onClose();
    }
    else if(status===0 || status===3){
      sendFriendRequest(invitee_id, false);
      onClose();
    }
  }
  render() {
    const { showModal, onClose, postLikeDetails, t } = this.props;
    return(
      <div>
        <StyledModal
          show={showModal}
          onHide={onClose}
        >
          <StyledHeader justify background>
            <HeaderText style={{justifyContent:"flex-start"}}>{t("LIKES")}</HeaderText>
            <CloseIcon width onClick={onClose} >
              <img src="../../../public/images/NewDashboardV2/new_close_icon.png" width="24px" height="24px"/>
            </CloseIcon>
            {/* <SendIcon>
              <img src="../../../public/images/social/send.png" />
            </SendIcon> */}
          </StyledHeader>
          <StyledBody>
            {
              postLikeDetails && postLikeDetails ? (
                postLikeDetails.map((data, index) => (
                  <Container key={index}>
                    <LeftSection>
                      <Img>
                        <img src={`https://s3.amazonaws.com/images.trainingamigo.com/${data.profile_image}`} style={{borderRadius:"50%"}}/>
                      </Img>
                      <Content>
                        <div>
                          {t(data.fullname)}
                        </div>
                        <div>
                          {t(data.department)}
                        </div>
                      </Content>
                    </LeftSection>
                    <RightSection status={data.friend} onClick={()=>this.handleRequest(data.friend, data.uid)}>
                      {
                        data.friend === 1 ? (
                          <Button status={data.friend} /*onClick={()=>this.handleRequest(data.friend, data.uid)}*/>{t("Buddies")}</Button>
                        ) : (
                          data.friend === 0 ? (
                            <Button status={data.friend} /*onClick={()=>this.handleRequest(data.friend, data.uid)}*/>{t("Add Buddy")}</Button>
                          ) : (
                            data.friend === 2 ? (
                              <Button status={data.friend} >{t("Pending")}</Button>
                            ) : null
                          )
                        )  
                      }
                      {/* <Button status={data.friend} onClick={()=>this.handleRequest(data.friend, data.uid)}>Remove</Button> */}
                    </RightSection>
                  </Container>
                ))
              ) : (
                null
              ) 
            }
            
          </StyledBody>
        </StyledModal>
      </div>
    )
  }
}

LikePopUp.propTypes =  {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  getPostLikeDetails: PropTypes.func,
  postLikeDetails: PropTypes.array,
  sendFriendRequest: PropTypes.func,
  unFriendUser: PropTypes.func,
  feedNumber : PropTypes.number,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  postLikeDetails : state.social.postLikeDetails,
  status : state.profileData.requestSent
})

const mapDispatchToProps = (dispatch) => ({
  getPostLikeDetails: (data) => dispatch(getPostLikeDetails(data)),
  unFriendUser: (data, bool) => dispatch(unFriendUser(data, bool)),
  sendFriendRequest: (data, bool) => dispatch(sendFriendRequest(data, bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LikePopUp));