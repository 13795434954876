import styled from 'styled-components';
import {Modal} from "react-bootstrap";

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 500px;
    ${'' /* height: 450px; */}
    border-radius: 6px;
    background: linear-gradient(180deg, #FFFFFF 0%,rgba(255,255,255,0) 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    .modal-content {
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
      border:none;
    }
    .close {
      margin-top: -30px;
    }
    ${'' /* @media (max-height: 1200px) {
      width: 55%;
    } */}
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  display: flex;
  padding: 15px 0;
  border-bottom: none;
  // background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
  background: ${({background}) => background ? '#005c87' : 'linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%)'};
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  justify-content: ${({justify}) => justify ? 'space-between' : ''}
  > span {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    color: #0D4270;
    text-decoration: underline;
    text-transform: uppercase;
  }
`;
const StyledBody = styled(Modal.Body)`
height: 100%;
width: 100%;
padding:30px;
`;

const TopSection = styled.div`

`;

const MiddleSection = styled.div`

`;

const Container = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const LeftSection = styled.div`
  width: 74%;
  display: flex;
`;

const RightSection = styled.div`
  width: 26%;
  display: flex;
  background: ${({status})=> (status===5 || status===4 || status===3) ? 'null' : status===0 ? '#FD7175' : status===1 ? '#9FC989' : '#f6b479'};
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  border: ${({status})=> (status===5 || status===4 || status===3) ? 'null' : status===0 ? 'null' : status===1 ? 'null' : 'none'};
  cursor: pointer;
  ${'' /* 1px solid #3A3A3A */}
`;

const Img = styled.div`
  width: 14%;
  >img{
    width: 45px;
    height: 45px;
  }
`;

const Content = styled.div`
  padding: 5px 0 5px 15px;
  width: 86%;
  >div:first-child{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #005c87;
  }
  >div:last-child{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #005c87;
  }
`;

const Button = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 2px;
  text-transform: capitalize;
  color: ${({status})=> (status===5 || status===4 || status===3) ? 'null' : status===0 ? '#FFFFFF' : status===1 ? '#FFFFFF' : '#FFFFFF'};
  ${'' /* #3A3A3A */}
`;

const HeaderText = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 80%;
    display: flex;
`;

export { TopSection, MiddleSection, StyledModal, StyledHeader, StyledBody, Container, LeftSection, RightSection, Img, Content, Button, HeaderText }
