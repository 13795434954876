import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 8vmin;
  text-align: center;
  min-width: 320px;
`;
const RadioButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 380px) {
     flex-direction: column;
  }
`;
export {Layout, RadioButtonContainer}